// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comingsoon-js": () => import("./../../src/pages/comingsoon.js" /* webpackChunkName: "component---src-pages-comingsoon-js" */),
  "component---src-pages-contact-index-js": () => import("./../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-deli-js": () => import("./../../src/pages/deli.js" /* webpackChunkName: "component---src-pages-deli-js" */),
  "component---src-pages-frozen-js": () => import("./../../src/pages/frozen.js" /* webpackChunkName: "component---src-pages-frozen-js" */),
  "component---src-pages-health-js": () => import("./../../src/pages/health.js" /* webpackChunkName: "component---src-pages-health-js" */),
  "component---src-pages-hours-index-js": () => import("./../../src/pages/hours/index.js" /* webpackChunkName: "component---src-pages-hours-index-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meat-js": () => import("./../../src/pages/meat.js" /* webpackChunkName: "component---src-pages-meat-js" */),
  "component---src-pages-produce-js": () => import("./../../src/pages/produce.js" /* webpackChunkName: "component---src-pages-produce-js" */),
  "component---src-pages-products-js": () => import("./../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-search-js": () => import("./../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

